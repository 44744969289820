@charset "UTF-8";
body {
  color: #565A5C;
  background-color: #0747A6;
}

.social-auth-title {
  text-align: center;
  color: #97A0AF;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
  margin-top: 40px;
}

#auth-form-container .panel-body {
  padding: 40px;
}

#header {
  padding: 32px;
  text-align: center;
}

#logo img {
  max-height: 48px;
  margin: 0 auto;
}

#header .title {
  color: #92d9ff;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 48px;
}

#footer {
  text-align: center;
  padding-bottom: 40px !important;
  padding-top: 40px !important;
}
#footer ul.footer-links {
  display: inline;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0;
}
#footer ul.footer-links li {
  display: inline-block;
  color: #fff;
}
#footer ul.footer-links li ::after {
  content: "·";
  margin: 0 5px;
}
#footer ul.footer-links li a {
  color: #7e8a9a;
}
#footer ul.footer-links li:last-of-type {
  content: "";
}