
#footer {
  text-align: center;
  padding-bottom: 40px !important;
  padding-top: 40px !important;

  ul.footer-links {
    display: inline;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0;

    li {
      display: inline-block;
      color: #fff;

      ::after {
        content: "·";
        margin: 0 5px;
      }

      a {
        color: $secondary;
      }
    }

    li:last-of-type {
      content: "";
    }
  }
}