
body {
  color: #565A5C;
  background-color: #0747A6;
}

.social-auth-title {
  text-align: center;
  color: #97A0AF;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
  margin-top: 40px;
}

#auth-form-container .panel-body {
  padding: 40px;
}
