
#header {
  padding: 32px;
  text-align: center;
}

#logo {
}

#logo img {
  max-height: 48px;
  margin: 0 auto;
}

#header .title {
  color: #92d9ff;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 48px;
}
